import { useStaticQuery, graphql } from 'gatsby'

// :::: GET Article from Article title ::::
export const getStrapiDescriptionFromTitle = title => {
  const {
    allStrapiBlog,
    allStrapiPublication,
    AmericasReview,
    allStrapiEvent
  } = useStaticQuery(allStrapiDescriptionQuery)
  const data = [
    ...allStrapiBlog.nodes,
    ...allStrapiPublication.nodes,
    ...AmericasReview.nodes,
    ...allStrapiEvent.nodes
  ]
  const filterData = data.filter(node => node.title === title)
  return filterData[0]
}

const allStrapiDescriptionQuery = graphql`
  query {
    allStrapiBlog {
      nodes {
        title
        description {
          data {
            description
          }
        }
        featuredImage {
          url
        }
        excerpt {
          data {
            excerpt
          }
        }
      }
    }

    allStrapiEvent {
      nodes {
        title
        description {
          data {
            description
          }
        }
        featuredImage {
          url
        }
        excerpt {
          data {
            excerpt
          }
        }
      }
    }

    allStrapiPublication {
      nodes {
        title
        description {
          data {
            description
          }
        }
        featuredImage {
          url
        }
        excerpt {
          data {
            excerpt
          }
        }
      }
    }

    AmericasReview: allStrapiTheAmericasQuarterlyReview {
      nodes {
        title
        description {
          data {
            description
          }
        }
        featuredImage {
          url
        }
        excerpt {
          data {
            excerpt
          }
        }
      }
    }
  }
`
