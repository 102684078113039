import React from 'react'
import { Layout, Stack, Main, Hero } from '@layout'
import Divider from '@components/Divider'
import Seo from '@widgets/Seo'
import CardList from '@components/CardList/CardList'
import { getPartnersData } from '../helpers/nr-partner-page.helper'
import HeroComponent from '../../../gatsby-theme-flexiblog-personal/src/components/Hero/Hero'
import MemphisPattern from '@components/MemphisPattern/MemphisPattern'

/**
 * Shadow me to add your own content
 */

export default props => {
  const { data, seo, hero } = getPartnersData()

  let seoData = {
    description: seo?.metaDescription,
    title: seo?.metaTitle,
    keywords: seo?.keywords?.split('\n'),
    metaImage: seo?.metaImage
  }

  return (
    <Layout {...props}>
      <Seo
        title={seoData?.title}
        description={seoData?.description}
        keywords={seoData?.keywords}
        thumbnail={seoData?.metaImage}
        socialNetwork={seoData?.social}
      />
      <Hero full>
        <Hero style={{ zIndex: 1 }}>
          <HeroComponent
            customHeroFlag={true}
            heroType={'partners'}
            {...props}
          />
        </Hero>
        <MemphisPattern customHeroBgFlag={true} />
      </Hero>
      <Divider />
      <Stack>
        <Main>
          <Divider />
          <CardList
            variant={['horizontal-md', 'horizontal']}
            nodes={data}
            columns={[1, 2, 2]}
            callFrom='partner'
            omitFooter
          />
        </Main>
      </Stack>
    </Layout>
  )
}
