import { useStaticQuery, graphql } from 'gatsby'
import { useBlogAuthors } from '@helpers-blog'

// :::: GET all the articles and returning Authors by selected article ::::
export const getSelectedArticleAuthors = title => {
  const { allStrapiBlog, allStrapiPublication ,allStrapiTheAmericasQuarterlyReview} =
    useStaticQuery(allArticlesQuery)
  const allData = [...allStrapiBlog.nodes, ...allStrapiPublication.nodes,...allStrapiTheAmericasQuarterlyReview.nodes]
  const selectedArticleAuthors = allData.filter(data => data.title === title)

  return (
    selectedArticleAuthors.length !== 0 && selectedArticleAuthors[0].authors
  )
}

const allArticlesQuery = graphql`
  query {
    allStrapiBlog {
      nodes {
        id
        title
        authors {
          id
          name
          title
          slug
          social {
            name
            url
          }
        }
      }
    }

    allStrapiPublication {
      nodes {
        id
        title
        authors {
          id
          name
          title
          slug
          social {
            name
            url
          }
        }
      }
    }  
    allStrapiTheAmericasQuarterlyReview {
      nodes {
        id
        title
        authors {
          id
          name
          title
          slug
          social {
            name
            url
          }
        }
      }
    }
  }
  
`
// :::: Attach thumbnail ::::
export const attachThumbnail = authors => {
  authors.forEach(author => {
    author['thumbnail'] = useBlogAuthors().find(
      authorWithThumbnail => authorWithThumbnail.name === author.name
    ).thumbnail
  })
}
