import { graphql } from 'gatsby'
import PostsPage from '../containers/Posts'

export default PostsPage

export const pageQuery = graphql`
  query PostsPageQuery(
    $paginatePostsPage: Boolean!
    $skip: Int
    $limit: Int
    $includeExcerpt: Boolean!
  ) {
    featuredPosts: allBlog(
      filter: { private: { ne: true }, featured: { eq: true } }
      sort: { fields: [date], order: DESC }
      limit: 10
    ) {
      nodes {
        ...BlogPreview
      }
    }

    seo: allStrapiHomePage {
      nodes {
        seo {
          metaDescription
          metaTitle
          metaImage {
            url
          }
          keywords
        }
      }
    }

    recentPosts: allBlog(
      filter: { private: { ne: true } }
      sort: { fields: [date], order: DESC }
      limit: 6
    ) {
      nodes {
        ...BlogPreview
      }
    }

    featuredBlog: allBlog(
      filter: {title: {eq: "Academy of American Franciscan History"}} 
    ) {
      nodes {
        id
        title
        slug
        excerpt
        featured
        date(formatString: "MMMM DD, YYYY")
        updatedAt(formatString: "MMMM DD, YYYY")
        authors {
          id
          name
          slug
          title
          description
          skills
          social {
            name
            url
          }
          thumbnail {
            __typename
            ... on ImageSharp {
              ImageSharp_small: gatsbyImageData(
                width: 48
                height: 48
                layout: FIXED
                transformOptions: {cropFocus: NORTH}
                placeholder: TRACED_SVG
                quality: 75
              )
              ImageSharp_regular: gatsbyImageData(
                width: 150
                height: 150
                layout: FIXED
                transformOptions: {cropFocus: NORTH}
                placeholder: TRACED_SVG
                quality: 80
              )
            }
            ... on ContentfulAsset {
              ContentfulAsset_small: gatsbyImageData(
                width: 48
                height: 48
                layout: FIXED
                cropFocus: TOP
                resizingBehavior: THUMB
                quality: 75
              )
              ContentfulAsset_regular: gatsbyImageData(
                width: 150
                height: 150
                layout: FIXED
                cropFocus: TOP
                resizingBehavior: THUMB
                quality: 80
              )
            }
            ... on SanityImageAsset {
              SanityImageAsset_small: gatsbyImageData(
                width: 48
                height: 48
                layout: FIXED
                placeholder: NONE
                fit: CLIP
              )
              SanityImageAsset_regular: gatsbyImageData(
                width: 150
                height: 150
                layout: FIXED
                placeholder: NONE
                fit: CLIP
              )
            }
          }
        }
        featuredImage {
          __typename
          ... on ImageSharp {
            ImageSharp_vertical: gatsbyImageData(
              width: 360
              height: 470
              transformOptions: {cropFocus: CENTER}
              outputPixelDensities: [0.5, 1]
            )
            ImageSharp_horizontal: gatsbyImageData(
              width: 807
              height: 400
              transformOptions: {cropFocus: CENTER}
              outputPixelDensities: [0.5, 1]
            )
            ImageSharp_hero: gatsbyImageData(
              width: 1600
              height: 650
              transformOptions: {cropFocus: CENTER}
              outputPixelDensities: [0.5, 1]
            )
          }
          ... on ContentfulAsset {
            ContentfulAsset_vertical: gatsbyImageData(
              width: 360
              height: 470
              cropFocus: CENTER
              formats: [JPG, WEBP]
              resizingBehavior: THUMB
            )
            ContentfulAsset_horizontal: gatsbyImageData(
              width: 807
              height: 400
              cropFocus: CENTER
              formats: [JPG, WEBP]
              resizingBehavior: THUMB
            )
            ContentfulAsset_hero: gatsbyImageData(
              width: 1600
              height: 650
              formats: [JPG, WEBP]
              resizingBehavior: THUMB
            )
          }
          ... on SanityImageAsset {
            SanityImageAsset_vertical: gatsbyImageData(
              width: 360
              height: 470
              outputPixelDensities: [0.5, 1]
            )
            SanityImageAsset_horizontal: gatsbyImageData(
              width: 807
              height: 400
              outputPixelDensities: [0.5, 1]
            )
            SanityImageAsset_hero: gatsbyImageData(
              width: 1600
              height: 650
              outputPixelDensities: [0.5, 1]
            )
          }
        }
      }
    }

    recentPublications: allPublication(
      filter: { private: { ne: true }, featured: { eq: true } }
      limit: 6
      sort: { fields: [date], order: DESC }
    ) {
      nodes {
        id
        title
        slug
        excerpt
        purchaseLink
        featured
        date(formatString: "MMMM DD, YYYY")
        category {
          ...BlogCategory
        }
        authors {
          id
          name
          slug
          title
          description
          skills
          social {
            name
            url
          }
          thumbnail {
            __typename
            ... on ImageSharp {
              ImageSharp_small: gatsbyImageData(
                width: 48
                height: 48
                layout: FIXED
                transformOptions: { cropFocus: NORTH }
                placeholder: TRACED_SVG
                quality: 75
              )
              ImageSharp_regular: gatsbyImageData(
                width: 150
                height: 150
                layout: FIXED
                transformOptions: { cropFocus: NORTH }
                placeholder: TRACED_SVG
                quality: 80
              )
            }
            ... on ContentfulAsset {
              ContentfulAsset_small: gatsbyImageData(
                width: 48
                height: 48
                layout: FIXED
                cropFocus: TOP
                resizingBehavior: THUMB
                quality: 75
              )
              ContentfulAsset_regular: gatsbyImageData(
                width: 150
                height: 150
                layout: FIXED
                cropFocus: TOP
                resizingBehavior: THUMB
                quality: 80
              )
            }
            ... on SanityImageAsset {
              SanityImageAsset_small: gatsbyImageData(
                width: 48
                height: 48
                layout: FIXED
                placeholder: NONE
                fit: CLIP
              )
              SanityImageAsset_regular: gatsbyImageData(
                width: 150
                height: 150
                layout: FIXED
                placeholder: NONE
                fit: CLIP
              )
            }
          }
        }

        featuredImage {
          __typename
          ... on ImageSharp {
            ImageSharp_vertical: gatsbyImageData(
              width: 360
              height: 470
              transformOptions: { cropFocus: CENTER }
              outputPixelDensities: [0.5, 1]
            )
            ImageSharp_horizontal: gatsbyImageData(
              width: 807
              height: 400
              transformOptions: { cropFocus: CENTER }
              outputPixelDensities: [0.5, 1]
            )
            ImageSharp_hero: gatsbyImageData(
              width: 1600
              height: 650
              transformOptions: { cropFocus: CENTER }
              outputPixelDensities: [0.5, 1]
            )
          }
          ... on ContentfulAsset {
            ContentfulAsset_vertical: gatsbyImageData(
              width: 360
              height: 470
              cropFocus: CENTER
              formats: [JPG, WEBP]
              resizingBehavior: THUMB
            )
            ContentfulAsset_horizontal: gatsbyImageData(
              width: 807
              height: 400
              cropFocus: CENTER
              formats: [JPG, WEBP]
              resizingBehavior: THUMB
            )
            ContentfulAsset_hero: gatsbyImageData(
              width: 1600
              height: 650
              formats: [JPG, WEBP]
              resizingBehavior: THUMB
            )
          }
          ... on SanityImageAsset {
            SanityImageAsset_vertical: gatsbyImageData(
              width: 360
              height: 470
              outputPixelDensities: [0.5, 1]
            )
            SanityImageAsset_horizontal: gatsbyImageData(
              width: 807
              height: 400
              outputPixelDensities: [0.5, 1]
            )
            SanityImageAsset_hero: gatsbyImageData(
              width: 1600
              height: 650
              outputPixelDensities: [0.5, 1]
            )
          }
        }
      }
    }

    homePagePublications: allPublication(
      filter: { private: { ne: true }}
      limit: 4
      sort: { fields: [date], order: DESC }
    ) {
      nodes {
        id
        title
        slug
        excerpt
        purchaseLink
        featured
        date(formatString: "MMMM DD, YYYY")
        category {
          ...BlogCategory
        }
        authors {
          id
          name
          slug
          title
          description
          skills
          social {
            name
            url
          }
          thumbnail {
            __typename
            ... on ImageSharp {
              ImageSharp_small: gatsbyImageData(
                width: 48
                height: 48
                layout: FIXED
                transformOptions: { cropFocus: NORTH }
                placeholder: TRACED_SVG
                quality: 75
              )
              ImageSharp_regular: gatsbyImageData(
                width: 150
                height: 150
                layout: FIXED
                transformOptions: { cropFocus: NORTH }
                placeholder: TRACED_SVG
                quality: 80
              )
            }
            ... on ContentfulAsset {
              ContentfulAsset_small: gatsbyImageData(
                width: 48
                height: 48
                layout: FIXED
                cropFocus: TOP
                resizingBehavior: THUMB
                quality: 75
              )
              ContentfulAsset_regular: gatsbyImageData(
                width: 150
                height: 150
                layout: FIXED
                cropFocus: TOP
                resizingBehavior: THUMB
                quality: 80
              )
            }
            ... on SanityImageAsset {
              SanityImageAsset_small: gatsbyImageData(
                width: 48
                height: 48
                layout: FIXED
                placeholder: NONE
                fit: CLIP
              )
              SanityImageAsset_regular: gatsbyImageData(
                width: 150
                height: 150
                layout: FIXED
                placeholder: NONE
                fit: CLIP
              )
            }
          }
        }

        featuredImage {
          __typename
          ... on ImageSharp {
            ImageSharp_vertical: gatsbyImageData(
              width: 360
              height: 470
              transformOptions: { cropFocus: CENTER }
              outputPixelDensities: [0.5, 1]
            )
            ImageSharp_horizontal: gatsbyImageData(
              width: 807
              height: 400
              transformOptions: { cropFocus: CENTER }
              outputPixelDensities: [0.5, 1]
            )
            ImageSharp_hero: gatsbyImageData(
              width: 1600
              height: 650
              transformOptions: { cropFocus: CENTER }
              outputPixelDensities: [0.5, 1]
            )
          }
          ... on ContentfulAsset {
            ContentfulAsset_vertical: gatsbyImageData(
              width: 360
              height: 470
              cropFocus: CENTER
              formats: [JPG, WEBP]
              resizingBehavior: THUMB
            )
            ContentfulAsset_horizontal: gatsbyImageData(
              width: 807
              height: 400
              cropFocus: CENTER
              formats: [JPG, WEBP]
              resizingBehavior: THUMB
            )
            ContentfulAsset_hero: gatsbyImageData(
              width: 1600
              height: 650
              formats: [JPG, WEBP]
              resizingBehavior: THUMB
            )
          }
          ... on SanityImageAsset {
            SanityImageAsset_vertical: gatsbyImageData(
              width: 360
              height: 470
              outputPixelDensities: [0.5, 1]
            )
            SanityImageAsset_horizontal: gatsbyImageData(
              width: 807
              height: 400
              outputPixelDensities: [0.5, 1]
            )
            SanityImageAsset_hero: gatsbyImageData(
              width: 1600
              height: 650
              outputPixelDensities: [0.5, 1]
            )
          }
        }
      }
    }

    recentBlog: allBlog(
      filter: { private: { ne: true }, featured: { eq: true } }
      sort: { fields: [date], order: DESC }
    ) {
      nodes {
        id
        title
        slug
        excerpt
        featured
        date(formatString: "MMMM DD, YYYY")
        updatedAt(formatString: "MMMM DD, YYYY")
        category {
          ...BlogCategory
        }
        authors {
          id
          name
          slug
          title
          description
          skills
          social {
            name
            url
          }
          thumbnail {
            __typename
            ... on ImageSharp {
              ImageSharp_small: gatsbyImageData(
                width: 48
                height: 48
                layout: FIXED
                transformOptions: { cropFocus: NORTH }
                placeholder: TRACED_SVG
                quality: 75
              )
              ImageSharp_regular: gatsbyImageData(
                width: 150
                height: 150
                layout: FIXED
                transformOptions: { cropFocus: NORTH }
                placeholder: TRACED_SVG
                quality: 80
              )
            }
            ... on ContentfulAsset {
              ContentfulAsset_small: gatsbyImageData(
                width: 48
                height: 48
                layout: FIXED
                cropFocus: TOP
                resizingBehavior: THUMB
                quality: 75
              )
              ContentfulAsset_regular: gatsbyImageData(
                width: 150
                height: 150
                layout: FIXED
                cropFocus: TOP
                resizingBehavior: THUMB
                quality: 80
              )
            }
            ... on SanityImageAsset {
              SanityImageAsset_small: gatsbyImageData(
                width: 48
                height: 48
                layout: FIXED
                placeholder: NONE
                fit: CLIP
              )
              SanityImageAsset_regular: gatsbyImageData(
                width: 150
                height: 150
                layout: FIXED
                placeholder: NONE
                fit: CLIP
              )
            }
          }
        }

        featuredImage {
          __typename
          ... on ImageSharp {
            ImageSharp_vertical: gatsbyImageData(
              width: 360
              height: 470
              transformOptions: { cropFocus: CENTER }
              outputPixelDensities: [0.5, 1]
            )
            ImageSharp_horizontal: gatsbyImageData(
              width: 807
              height: 400
              transformOptions: { cropFocus: CENTER }
              outputPixelDensities: [0.5, 1]
            )
            ImageSharp_hero: gatsbyImageData(
              width: 1600
              height: 650
              transformOptions: { cropFocus: CENTER }
              outputPixelDensities: [0.5, 1]
            )
          }
          ... on ContentfulAsset {
            ContentfulAsset_vertical: gatsbyImageData(
              width: 360
              height: 470
              cropFocus: CENTER
              formats: [JPG, WEBP]
              resizingBehavior: THUMB
            )
            ContentfulAsset_horizontal: gatsbyImageData(
              width: 807
              height: 400
              cropFocus: CENTER
              formats: [JPG, WEBP]
              resizingBehavior: THUMB
            )
            ContentfulAsset_hero: gatsbyImageData(
              width: 1600
              height: 650
              formats: [JPG, WEBP]
              resizingBehavior: THUMB
            )
          }
          ... on SanityImageAsset {
            SanityImageAsset_vertical: gatsbyImageData(
              width: 360
              height: 470
              outputPixelDensities: [0.5, 1]
            )
            SanityImageAsset_horizontal: gatsbyImageData(
              width: 807
              height: 400
              outputPixelDensities: [0.5, 1]
            )
            SanityImageAsset_hero: gatsbyImageData(
              width: 1600
              height: 650
              outputPixelDensities: [0.5, 1]
            )
          }
        }
      }
    }

    recentAmericasQuaterlyReview: allTheAmericasQuarterlyReview(
      filter: { private: { ne: true }, featured: { eq: true } }
      sort: { fields: [date], order: DESC }
    ) {
      nodes {
        id
        title
        slug
        excerpt
        featured
        date(formatString: "MMMM DD, YYYY")
        updatedAt(formatString: "MMMM DD, YYYY")
        authors {
          id
          name
          slug
          title
          description
          skills
          social {
            name
            url
          }
          thumbnail {
            __typename
            ... on ImageSharp {
              ImageSharp_small: gatsbyImageData(
                width: 48
                height: 48
                layout: FIXED
                transformOptions: { cropFocus: NORTH }
                placeholder: TRACED_SVG
                quality: 75
              )
              ImageSharp_regular: gatsbyImageData(
                width: 150
                height: 150
                layout: FIXED
                transformOptions: { cropFocus: NORTH }
                placeholder: TRACED_SVG
                quality: 80
              )
            }
            ... on ContentfulAsset {
              ContentfulAsset_small: gatsbyImageData(
                width: 48
                height: 48
                layout: FIXED
                cropFocus: TOP
                resizingBehavior: THUMB
                quality: 75
              )
              ContentfulAsset_regular: gatsbyImageData(
                width: 150
                height: 150
                layout: FIXED
                cropFocus: TOP
                resizingBehavior: THUMB
                quality: 80
              )
            }
            ... on SanityImageAsset {
              SanityImageAsset_small: gatsbyImageData(
                width: 48
                height: 48
                layout: FIXED
                placeholder: NONE
                fit: CLIP
              )
              SanityImageAsset_regular: gatsbyImageData(
                width: 150
                height: 150
                layout: FIXED
                placeholder: NONE
                fit: CLIP
              )
            }
          }
        }

        featuredImage {
          __typename
          ... on ImageSharp {
            ImageSharp_vertical: gatsbyImageData(
              width: 360
              height: 470
              transformOptions: { cropFocus: CENTER }
              outputPixelDensities: [0.5, 1]
            )
            ImageSharp_horizontal: gatsbyImageData(
              width: 807
              height: 400
              transformOptions: { cropFocus: CENTER }
              outputPixelDensities: [0.5, 1]
            )
            ImageSharp_hero: gatsbyImageData(
              width: 1600
              height: 650
              transformOptions: { cropFocus: CENTER }
              outputPixelDensities: [0.5, 1]
            )
          }
          ... on ContentfulAsset {
            ContentfulAsset_vertical: gatsbyImageData(
              width: 360
              height: 470
              cropFocus: CENTER
              formats: [JPG, WEBP]
              resizingBehavior: THUMB
            )
            ContentfulAsset_horizontal: gatsbyImageData(
              width: 807
              height: 400
              cropFocus: CENTER
              formats: [JPG, WEBP]
              resizingBehavior: THUMB
            )
            ContentfulAsset_hero: gatsbyImageData(
              width: 1600
              height: 650
              formats: [JPG, WEBP]
              resizingBehavior: THUMB
            )
          }
          ... on SanityImageAsset {
            SanityImageAsset_vertical: gatsbyImageData(
              width: 360
              height: 470
              outputPixelDensities: [0.5, 1]
            )
            SanityImageAsset_horizontal: gatsbyImageData(
              width: 807
              height: 400
              outputPixelDensities: [0.5, 1]
            )
            SanityImageAsset_hero: gatsbyImageData(
              width: 1600
              height: 650
              outputPixelDensities: [0.5, 1]
            )
          }
        }
      }
    }

    recentEvent: allEvent(
      filter: { private: { ne: true }, featured: { eq: true } }
      sort: { fields: [date], order: DESC }
    ) {
      nodes {
        id
        title
        slug
        excerpt
        featured
        eventLink
        location
        date(formatString: "MMMM DD, YYYY")
        updatedAt(formatString: "MMMM DD, YYYY")
        category {
          ...BlogCategory
        }

        featuredImage {
          __typename
          ... on ImageSharp {
            ImageSharp_vertical: gatsbyImageData(
              width: 360
              height: 470
              transformOptions: { cropFocus: CENTER }
              outputPixelDensities: [0.5, 1]
            )
            ImageSharp_horizontal: gatsbyImageData(
              width: 807
              height: 400
              transformOptions: { cropFocus: CENTER }
              outputPixelDensities: [0.5, 1]
            )
            ImageSharp_hero: gatsbyImageData(
              width: 1600
              height: 650
              transformOptions: { cropFocus: CENTER }
              outputPixelDensities: [0.5, 1]
            )
          }
          ... on ContentfulAsset {
            ContentfulAsset_vertical: gatsbyImageData(
              width: 360
              height: 470
              cropFocus: CENTER
              formats: [JPG, WEBP]
              resizingBehavior: THUMB
            )
            ContentfulAsset_horizontal: gatsbyImageData(
              width: 807
              height: 400
              cropFocus: CENTER
              formats: [JPG, WEBP]
              resizingBehavior: THUMB
            )
            ContentfulAsset_hero: gatsbyImageData(
              width: 1600
              height: 650
              formats: [JPG, WEBP]
              resizingBehavior: THUMB
            )
          }
          ... on SanityImageAsset {
            SanityImageAsset_vertical: gatsbyImageData(
              width: 360
              height: 470
              outputPixelDensities: [0.5, 1]
            )
            SanityImageAsset_horizontal: gatsbyImageData(
              width: 807
              height: 400
              outputPixelDensities: [0.5, 1]
            )
            SanityImageAsset_hero: gatsbyImageData(
              width: 1600
              height: 650
              outputPixelDensities: [0.5, 1]
            )
          }
        }
      }
    }

    homePageEvent: allEvent(
      filter: { private: { ne: true }},
      limit:3,
      sort: { fields: [date], order: DESC }
    ) {
      nodes {
        id
        title
        slug
        excerpt
        featured
        eventLink
        location
        date(formatString: "MMMM DD, YYYY")
        updatedAt(formatString: "MMMM DD, YYYY")
        category {
          ...BlogCategory
        }

        featuredImage {
          __typename
          ... on ImageSharp {
            ImageSharp_vertical: gatsbyImageData(
              width: 360
              height: 470
              transformOptions: { cropFocus: CENTER }
              outputPixelDensities: [0.5, 1]
            )
            ImageSharp_horizontal: gatsbyImageData(
              width: 807
              height: 400
              transformOptions: { cropFocus: CENTER }
              outputPixelDensities: [0.5, 1]
            )
            ImageSharp_hero: gatsbyImageData(
              width: 1600
              height: 650
              transformOptions: { cropFocus: CENTER }
              outputPixelDensities: [0.5, 1]
            )
          }
          ... on ContentfulAsset {
            ContentfulAsset_vertical: gatsbyImageData(
              width: 360
              height: 470
              cropFocus: CENTER
              formats: [JPG, WEBP]
              resizingBehavior: THUMB
            )
            ContentfulAsset_horizontal: gatsbyImageData(
              width: 807
              height: 400
              cropFocus: CENTER
              formats: [JPG, WEBP]
              resizingBehavior: THUMB
            )
            ContentfulAsset_hero: gatsbyImageData(
              width: 1600
              height: 650
              formats: [JPG, WEBP]
              resizingBehavior: THUMB
            )
          }
          ... on SanityImageAsset {
            SanityImageAsset_vertical: gatsbyImageData(
              width: 360
              height: 470
              outputPixelDensities: [0.5, 1]
            )
            SanityImageAsset_horizontal: gatsbyImageData(
              width: 807
              height: 400
              outputPixelDensities: [0.5, 1]
            )
            SanityImageAsset_hero: gatsbyImageData(
              width: 1600
              height: 650
              outputPixelDensities: [0.5, 1]
            )
          }
        }
      }
    }

    singlePublication: allPublication(
      filter: { private: { ne: true }, featured: { eq: true } }
      sort: { fields: [date], order: DESC }
    ) {
      nodes {
        id
        title
        slug
        excerpt
        purchaseLink
        featured
        date(formatString: "MMMM DD, YYYY")
        updatedAt(formatString: "MMMM DD, YYYY")
        category {
          ...BlogCategory
        }
        authors {
          id
          name
          slug
          title
          description
          skills
          social {
            name
            url
          }
          thumbnail {
            __typename
            ... on ImageSharp {
              ImageSharp_small: gatsbyImageData(
                width: 48
                height: 48
                layout: FIXED
                transformOptions: { cropFocus: NORTH }
                placeholder: TRACED_SVG
                quality: 75
              )
              ImageSharp_regular: gatsbyImageData(
                width: 150
                height: 150
                layout: FIXED
                transformOptions: { cropFocus: NORTH }
                placeholder: TRACED_SVG
                quality: 80
              )
            }
            ... on ContentfulAsset {
              ContentfulAsset_small: gatsbyImageData(
                width: 48
                height: 48
                layout: FIXED
                cropFocus: TOP
                resizingBehavior: THUMB
                quality: 75
              )
              ContentfulAsset_regular: gatsbyImageData(
                width: 150
                height: 150
                layout: FIXED
                cropFocus: TOP
                resizingBehavior: THUMB
                quality: 80
              )
            }
            ... on SanityImageAsset {
              SanityImageAsset_small: gatsbyImageData(
                width: 48
                height: 48
                layout: FIXED
                placeholder: NONE
                fit: CLIP
              )
              SanityImageAsset_regular: gatsbyImageData(
                width: 150
                height: 150
                layout: FIXED
                placeholder: NONE
                fit: CLIP
              )
            }
          }
        }

        featuredImage {
          __typename
          ... on ImageSharp {
            ImageSharp_vertical: gatsbyImageData(
              width: 360
              height: 470
              transformOptions: { cropFocus: CENTER }
              outputPixelDensities: [0.5, 1]
            )
            ImageSharp_horizontal: gatsbyImageData(
              width: 807
              height: 400
              transformOptions: { cropFocus: CENTER }
              outputPixelDensities: [0.5, 1]
            )
            ImageSharp_hero: gatsbyImageData(
              width: 1600
              height: 650
              transformOptions: { cropFocus: CENTER }
              outputPixelDensities: [0.5, 1]
            )
          }
          ... on ContentfulAsset {
            ContentfulAsset_vertical: gatsbyImageData(
              width: 360
              height: 470
              cropFocus: CENTER
              formats: [JPG, WEBP]
              resizingBehavior: THUMB
            )
            ContentfulAsset_horizontal: gatsbyImageData(
              width: 807
              height: 400
              cropFocus: CENTER
              formats: [JPG, WEBP]
              resizingBehavior: THUMB
            )
            ContentfulAsset_hero: gatsbyImageData(
              width: 1600
              height: 650
              formats: [JPG, WEBP]
              resizingBehavior: THUMB
            )
          }
          ... on SanityImageAsset {
            SanityImageAsset_vertical: gatsbyImageData(
              width: 360
              height: 470
              outputPixelDensities: [0.5, 1]
            )
            SanityImageAsset_horizontal: gatsbyImageData(
              width: 807
              height: 400
              outputPixelDensities: [0.5, 1]
            )
            SanityImageAsset_hero: gatsbyImageData(
              width: 1600
              height: 650
              outputPixelDensities: [0.5, 1]
            )
          }
        }
      }
    }

    posts: allBlog(
      filter: { private: { ne: true } }
      sort: { fields: [date], order: DESC }
      limit: 1000
    ) @skip(if: $paginatePostsPage) {
      group(field: category___name, limit: 10) {
        categoryName: fieldValue
        nodes {
          ...BlogPreview
        }
      }
    }

    paginatedPosts: allBlog(
      filter: { private: { ne: true } }
      sort: { fields: [date], order: DESC }
      limit: $limit
      skip: $skip
    ) @include(if: $paginatePostsPage) {
      nodes {
        ...BlogPreview
      }
      ...BlogPagination
    }

    homePagePosts:  allBlog(
      filter: { private: { ne: true }},
      limit:3,
      sort: { fields: [date], order: DESC }
    ) {
      nodes {
        id
        title
        slug
        excerpt
        featured
        date(formatString: "MMMM DD, YYYY")
        updatedAt(formatString: "MMMM DD, YYYY")
        category {
          ...BlogCategory
        }

        featuredImage {
          __typename
          ... on ImageSharp {
            ImageSharp_vertical: gatsbyImageData(
              width: 360
              height: 470
              transformOptions: { cropFocus: CENTER }
              outputPixelDensities: [0.5, 1]
            )
            ImageSharp_horizontal: gatsbyImageData(
              width: 807
              height: 400
              transformOptions: { cropFocus: CENTER }
              outputPixelDensities: [0.5, 1]
            )
            ImageSharp_hero: gatsbyImageData(
              width: 1600
              height: 650
              transformOptions: { cropFocus: CENTER }
              outputPixelDensities: [0.5, 1]
            )
          }
          ... on ContentfulAsset {
            ContentfulAsset_vertical: gatsbyImageData(
              width: 360
              height: 470
              cropFocus: CENTER
              formats: [JPG, WEBP]
              resizingBehavior: THUMB
            )
            ContentfulAsset_horizontal: gatsbyImageData(
              width: 807
              height: 400
              cropFocus: CENTER
              formats: [JPG, WEBP]
              resizingBehavior: THUMB
            )
            ContentfulAsset_hero: gatsbyImageData(
              width: 1600
              height: 650
              formats: [JPG, WEBP]
              resizingBehavior: THUMB
            )
          }
          ... on SanityImageAsset {
            SanityImageAsset_vertical: gatsbyImageData(
              width: 360
              height: 470
              outputPixelDensities: [0.5, 1]
            )
            SanityImageAsset_horizontal: gatsbyImageData(
              width: 807
              height: 400
              outputPixelDensities: [0.5, 1]
            )
            SanityImageAsset_hero: gatsbyImageData(
              width: 1600
              height: 650
              outputPixelDensities: [0.5, 1]
            )
          }
        }
      }
    }}
`
