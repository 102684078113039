import React, { useEffect } from 'react'
import { Card as CardComponent, Button, Heading, Box, Text } from 'theme-ui'
import { Layout, Stack, Main, Sidebar, Hero } from '@layout'
import CardList from '@components/CardList'
import Divider from '@components/Divider'
import Sticky from '@components/Sticky'
import { Link } from 'gatsby'
import Seo from '@widgets/Seo'
import AuthorCompact from '@widgets/AuthorCompact'
import Categories from '@widgets/Categories'
import OpenMapSource from './Map'
import { PostHead, PostImage, PostBody } from '@widgets/Post'
import { useBlogCategories } from '@helpers-blog'
import {
  getSelectedArticleAuthors,
  attachThumbnail
} from '@helpers-blog/nr-blogAuthors.helper.js'
import './blog.css'
import theme from '@elegantstack/flow-ui-theme/src/theme'
import { getStrapiDescriptionFromTitle } from '@helpers-blog/nr-useBlogDescription.helper'
import { attachCategoriesToPost, attachSeoDetails } from '@utils-blog'
import { getPostSeoData } from '@helpers-blog/nr-postSeoData.helper.js'

const styles = {
  innerSection: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between'
  },

  buttons: {
    a: {
      borderRadius: `12px`
    }
  },
  image: image => ({
    display: [`none`, null, `block`],
    height: 305,
    width: `1/4`,
    minWidth: 457,
    maxHeight: 305,
    minHeight: 305,
    borderRadius: `0px 50px 50px 50px`,
    backgroundImage: `url(${image})`,
    backgroundRepeat: `no-repeat`,
    backgroundSize: `cover`,
    backgroundPosition: `center`,
    overflowX: `hidden`,
    zIndex: 2
  }),

  border: {
    display: 'none',
    '@media screen and (min-width: 1024px)': {
      display: 'block'
    },
    '&::before, &::after': {
      content: `""`,
      position: `absolute`
    },
    '::before': {
      bg: `#E4E7EB`,
      top: `-6px`,
      left: `calc(100% - 463px)`,
      width: `175px`,
      height: `133px`,
      zIndex: -1
    },
    '::after': {
      bg: `#DBE0E7`,
      width: `175px`,
      height: `133px`,
      right: `-5px`,
      top: `178px`,
      borderRadius: `0px 18px 50px 18px`,
      zIndex: -1
    }
  },
  mainTitle: {
    fontSize: '3rem',
    fontFamily: 'Inter, sans-serif',
    fontWeight: '900',
    lineHeight: '1.33',
    // maxWidth: '720px',
    marginBottom: '24px'
  },
  date: {
    fontSize: '15px',
    fontFamily: 'Inter, sans-serif',
    fontWeight: '400',
    marginBottom: '26px'
  },

  eventLink: {
    width: 'fit-content',
    fontSize: '18px',
    fontWeight: '400',
    padding: '10px 20px',
    borderRadius: '12px',
    border: 'none'
  },
  wrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    position: 'relative'
  },
  innerWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    width: '100%',
    '@media screen and (min-width: 1024px)': {
      width: '50%'
    }
  }
}

const Blog = ({
  data: { blogDetail, publicationDetail, eventDetail, americasReview },
  ...props
}) => {
  const { pageContext: { services = {}, siteUrl } = {} } = props
  let post = blogDetail
    ? blogDetail
    : publicationDetail
    ? publicationDetail
    : americasReview
    ? americasReview
    : eventDetail

  const { name } = props.pageContext
  let categories = useBlogCategories()

  if (name === 'blogPage') {
    categories = categories.filter(item => item.type === 'News')
    post.callFrom="Blog"
  }

  if (name === 'publicationPage') {
    categories = categories.filter(item => item.type === 'Publication')
    post.callFrom="Publications" 
  }

  if (name === 'americasReviewPage') {
    categories = categories.filter(
      item => item.type === 'The Americas Quarterly Review'
    )
    post.callFrom="AmericasReview" 
  }

  useEffect(() => {
    const { linkColor, linkHoverColor } = theme.colors
    document
      .querySelector(':root')
      .style.setProperty('--anchor-tag-color', linkColor)
    document
      .querySelector(':root')
      .style.setProperty('--anchor-tag-hover-color', linkHoverColor)
  }, [])

  const SEO = getPostSeoData(post.title)
  let seoData = {
    description: SEO?.metaDescription,
    title: SEO?.metaTitle,
    keywords: SEO?.keywords?.split('\n'),
    metaImage: SEO?.metaImage
  }
  let authors

  attachBodyContent(post)
  attachCategoriesToPost(post)
  function formatDate(date) {
    const formattedDate = new Date(date).toDateString(
      {},
      { timeZone: 'UTC', month: 'long', day: '2-digit', year: 'numeric' }
    )
    const sp = formattedDate.split(' ')
    return `${sp[1]}, ${sp[2]} ${new Date().getFullYear()}`
  }

  post.name = name
  if (post.bodyContent) {
    const text = post.bodyContent.replace(/(<([^>]+)>)/gi, '')
  }

  if (name !== 'Event') {
    authors = getSelectedArticleAuthors(post.title)
    if (authors) {
      attachThumbnail(authors)
    }
  }

  function attachBodyContent(post) {
    let blog = getStrapiDescriptionFromTitle(post.title)

    post['bodyContent'] = blog.description.data.description
    if (name === 'Event') {
      post['imageUrl'] = blog.featuredImage
    }
  }

  return name !== 'Event' ? (
    <Layout {...props}>
      <Seo
        title={seoData?.title}
        description={seoData?.description}
        keywords={seoData?.keywords}
        thumbnail={seoData?.metaImage}
        socialNetwork={seoData?.social}
        siteUrl={siteUrl}
      />
      <Divider />
      <Stack effectProps={{ effect: 'fadeInDown' }}>
        <PostHead {...post} />
      </Stack>
      <Divider />
      <Stack effectProps={{ fraction: 0 }}>
        <Main>
          <CardComponent variant='paper'>
            {name !== 'publicationPage' && name !=='americasReviewPage' ? <PostImage {...post} inCard /> : null}
            <PostBody {...post} />
          </CardComponent>
        </Main>
        <Sidebar sx={{ display: [`none`, `none`, `none`, `block`] }}>
          <AuthorCompact authors={authors} omitTitle />
          <Divider />
          <Categories categories={categories} />
          <Divider />
          {post.category && (
            <Sticky>
              <CardList
                title='Related Posts'
                nodes={relatedPosts}
                variant='horizontal-aside'
                omitMedia
                omitCategory
                limit={6}
                distinct
                aside
              />
            </Sticky>
          )}
        </Sidebar>
      </Stack>
    </Layout>
  ) : (
    <Layout {...props}>
      <Seo
        title={seoData?.title}
        description={seoData?.description}
        keywords={seoData?.keywords}
        thumbnail={seoData?.metaImage}
        socialNetwork={seoData?.social}
        siteUrl={siteUrl}
      />
      <Box style={{ paddingTop: '70px' }}>
        <Hero full>
          <Stack>
            <Box sx={styles.wrapper}>
              <Box sx={styles.innerWrapper}>
              <Heading  sx={styles.mainTitle} dangerouslySetInnerHTML={{ __html: post.title }} />

                {name === 'Event' && (
                  <Text variant='small' sx={styles.date}>
                    {formatDate(post.date)} At{' '}
                    {new Date(post.date).toTimeString().slice(0, 5)} Hours
                  </Text>
                )}
                <Box variant='buttons.group' sx={styles.buttons}>
                    {post.eventLink
                      && <Button as={Link} to={post.eventLink} target='blank'>
                        Event Link Here
                      </Button>}
                </Box>
              </Box>
              <Box sx={styles.border}>
                <Box sx={styles.image(post.imageUrl.url || {})}></Box>
              </Box>
            </Box>
          </Stack>
        </Hero>
        <Stack
          style={{
            padding: '16px 0px 35px 0px'
          }}
        >
          <Box>
            <Box
              sx={{
                fontFamily: 'Inter, sans-serif',
                lineHeight: '1.5'
              }}
            >
              <Divider />

              <PostBody {...post} />
            </Box>
            <Divider />
            {post.location ? (
              <OpenMapSource coordinates={post.location} />
            ) : null}
          </Box>
        </Stack>
      </Box>
    </Layout>
  )
}

export default Blog
