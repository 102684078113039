import React from 'react'
// import PropTypes from 'prop-types';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet'

const OpenMapSource = props => {
  let value = props.coordinates ? props.coordinates.split(' ') : []
  return (
    <div style={{ width: '100%' }}>
      <MapContainer
        style={{ height: '540px', width: '100%' }}
        center={
          value.length > 0
            ? [Number(value[0]), Number(value[1])]
            : [51.505, -0.09]
        }
        zoom={13}
        scrollWheelZoom={false}
      >
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
        />
        <Marker
          position={
            value.length > 0
              ? [Number(value[0]), Number(value[1])]
              : [51.505, -0.09]
          }
        >
          <Popup>
            Event location {value[0]} {value[1]}
          </Popup>
        </Marker>
      </MapContainer>
    </div>
  )
}

export default OpenMapSource
