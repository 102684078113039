import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { Box } from 'theme-ui'

const styles = {
  image: image => ({
    display: [`none`, null, `block`],
    height: `100%`,
    width: `100%`,
    // minWidth: 380,
    // maxHeight: 500,
    borderRadius: `default`,
    backgroundImage: `url(${image})`,
    backgroundRepeat: `no-repeat`,
    backgroundSize: `contain`,
    backgroundPosition: `bottom left`,
    overflowX: `hidden`,
    // position: `absolute`,
    // bottom: 0,
    // left: 30,
    // zIndex: 2
  })
}

export default () => {
  const { allStrapiHomePage } = useStaticQuery(testimonialQuery)
  const publicURL =
    allStrapiHomePage?.nodes[0]?.callToAction?.featuredImage?.url

  if (!publicURL) return ''

  return <Box sx={styles.image(publicURL || {})}></Box>
}

const testimonialQuery = graphql`
  query {
    allStrapiHomePage {
      nodes {
        callToAction {
          featuredImage {
            url
          }
        }
      }
    }
  }
`
