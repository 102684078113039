import React from 'react'
import { Layout, Stack, Hero, Main, Sidebar, PreFooter } from '@layout'
import CardList from '@components/CardList'
import Sticky from '@components/Sticky'
import Divider from '@components/Divider'
import Pagination from '@components/Pagination'
import Seo from '@widgets/Seo'
import Categories from '@widgets/Categories'
import { useBlogTags, useBlogCategories } from '@helpers-blog'
import { attactExcerptToPosts } from '@utils-blog/nr-useArticlesUtils.jsx'
import { getHeroData } from '@helpers-blog/nr-blogsHero.helper.js'
import HeroComponent from '../../../../themes/gatsby-theme-flexiblog-personal/src/components/Hero/Hero'
import MemphisPattern from '@components/MemphisPattern/MemphisPattern'

// import { readingTime } from 'reading-time-estimator'

const Blogs = props => {
  let categories = useBlogCategories()
  categories = categories.filter(item => item.type === 'News')

  const tags = useBlogTags()
  const paginatedPosts = props.data.paginated

  attactExcerptToPosts(paginatedPosts)

  const { blogs } = getHeroData()
  let seoData = {
    description: blogs?.seo?.metaDescription,
    title: blogs?.seo?.metaTitle,
    keywords: blogs?.seo?.keywords?.split('\n'),
    metaImage: blogs?.seo?.metaImage
  }
  //   attachExcerptToEvent(paginatedPosts)

  //   paginatedPosts.nodes.forEach(ele => {
  //     ele.bodyContent = getReadTimeFromBlog(ele.title)
  //     if (ele.bodyContent && ele.bodyContent.body) {
  //       const text = ele.bodyContent.body.data.body.replace(/(<([^>]+)>)/gi, '')
  //       ele.readingTime = readingTime(text, 220)
  //     }
  //   })

  // attachCategoriesToEvent(paginatedPosts)
  const { pageContext: { services = {}, basePath } = {} } = props
  return (
    <Layout {...props}>
      <Seo
        title={seoData?.title}
        description={seoData?.description}
        keywords={seoData?.keywords}
        thumbnail={seoData?.metaImage}
        socialNetwork={seoData?.social}
      />
      <Hero full>
        <Hero style={{ zIndex: 1 }}>
          <HeroComponent customHeroFlag={true} heroType={'blogs'} {...props} />
        </Hero>
        <MemphisPattern customHeroBgFlag={true} />
      </Hero>
      <Divider />
      <Stack>
        <Main>
          <CardList
            variant={['horizontal-md', 'vertical']}
            callFrom='News'
            nodes={paginatedPosts.nodes}
            columns={[1, 2, 3, 3]}
          />
        </Main>
        <Sidebar>
          <Sticky>
            <Categories categories={categories} />
            <Divider />
            {/* <Tags tags={tags} />
          <Divider />
          <Social />
            {services.mailchimp && (
              <>
                <Divider />
                <NewsletterCompact />
              </>
            )} */}
          </Sticky>
        </Sidebar>
      </Stack>
      <Divider />
      <PreFooter>
        <Pagination {...paginatedPosts.pageInfo} prefix={'news'} />
      </PreFooter>
    </Layout>
  )
}
export default Blogs
