import React from 'react'
import { Layout, Stack, Main, PreFooter } from '@layout'
import PageTitle from '@components/PageTitle'
import Pagination from '@components/Pagination'
import CardList from '@components/CardList'
import Divider from '@components/Divider'
import Seo from '@widgets/Seo'
import AuthorExpanded from '@widgets/AuthorExpanded'
import { attachPostDetails, attachAuthorDetails } from '@utils-blog'

const PageCollectionAuthors = ({
  data: { blogs, publications, amricasReview, collectionInfo },
  ...props
}) => {
  attachAuthorDetails(collectionInfo)

  blogs.nodes = addKey(blogs.nodes, 'News')
  // posts.nodes = addKey(posts.nodes, 'Post')

  publications.nodes = addKey(publications.nodes, 'Publication')
  amricasReview.nodes = addKey(
    amricasReview.nodes,
    'The Americas Quarterly Review'
  )
  // events.nodes = addKey(events.nodes, 'Event')

  let allAuthorsData = [
    ...blogs.nodes,
    ...publications.nodes,
    ...amricasReview.nodes
  ]
  const item = groupBy([...allAuthorsData], c => c.belongTo)

  function groupBy(xs, f) {
    return xs.reduce(
      (r, v, i, a, k = f(v)) => ((r[k] || (r[k] = [])).push(v), r),
      {}
    )
  }

  function addKey(list, key) {
    list.forEach(item => {
      item.belongTo = key
    })
    return list
  }
  const object = { nodes: allAuthorsData }
  attachPostDetails(object) // Attach missing post details

  const SEO = collectionInfo?.seo
  let seoData = {
    description: SEO?.metaDescription,
    title: SEO?.metaTitle,
    keywords: SEO?.keywords?.split('\n'),
    metaImage: SEO?.metaImage
  }

  return (
    <Layout {...props}>
      <Seo
        title={seoData?.title}
        description={seoData?.description}
        keywords={seoData?.keywords}
        thumbnail={seoData?.metaImage}
        socialNetwork={seoData?.social}
      />
      <Divider />
      <Stack effectProps={{ effect: 'fadeInDown' }}>
        <PageTitle
          header='Published by Author'
          totalCount={allAuthorsData.length}
        />
      </Stack>
      <Divider />
      <Stack>
        <Main>
          <AuthorExpanded author={collectionInfo} />
          <Divider />
          {Object.keys(item).map(key => {
            return (
              <Stack title={key}>
                <Main>
                  {item[key] && (
                    <>
                      <CardList
                        nodes={item[key]}
                        variant={['horizontal-md', 'vertical']}
                        columns={[1, 2, 3, 3]}
                        callFrom={key}
                        omitCategory
                      />
                      <Divider />
                    </>
                  )}
                </Main>
                <Divider />
              </Stack>
            )
          })}
        </Main>
      </Stack>
      <Divider />
      {/* <PreFooter>
        <Pagination
          {...posts.pageInfo}
          {...collectionInfo}
          {...{ slug: `/author/${collectionInfo.slug}/` }}
        />
      </PreFooter> */}
    </Layout>
  )
}

export default PageCollectionAuthors
