import { useStaticQuery, graphql } from 'gatsby'

export const getAboutData = () => {
  const { allStrapiAboutPage } = useStaticQuery(aboutDataQuery)
  return {
    data: allStrapiAboutPage?.nodes[0],
    seo: allStrapiAboutPage?.nodes[0]?.seo,
    hero: allStrapiAboutPage?.nodes[0]?.hero
  }
}

const aboutDataQuery = graphql`
  query {
    allStrapiAboutPage {
      nodes {
        description {
          data {
            description
          }
        }
        title
        seo {
          metaImage {
            url
          }
          metaTitle
          metaDescription
          keywords
        }

        hero {
          title
          description
          featuredImage {
            ImageSharp_vertical: localFileImageSharp {
              gatsbyImageData(aspectRatio: 3.5)
            }
          }
          buttons {
            url
            name
          }
        }
      }
    }
  }
`
