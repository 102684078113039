import { useStaticQuery, graphql } from 'gatsby'

export const getStaffData = () => {
  const { allStrapiStaff, allStrapiStaffsPage } = useStaticQuery(staffDataQuery)
  return {
    data: allStrapiStaff?.nodes,
    hero: allStrapiStaffsPage?.nodes[0]?.hero,
    seo: allStrapiStaffsPage?.nodes[0]?.seo
  }
}

const staffDataQuery = graphql`
  query {
    allStrapiStaff {
      nodes {
        type
        strapi_id
        name
        description {
          data {
            description
          }
        }
        publishedAt
        featuredImage {
          localFileImageSharp {
            id
            gatsbyImageData(width: 130)
          }
          url
        }
      }
    }

    allStrapiStaffsPage {
      nodes {
        seo {
          keywords
          metaDescription
          metaTitle
          metaImage {
            url
          }
        }
        hero {
          title
          description
          featuredImage {
            ImageSharp_vertical: localFileImageSharp {
              gatsbyImageData(aspectRatio: 3.5)
            }
          }
          buttons {
            url
            name
          }
        }
      }
    }
  }
`
