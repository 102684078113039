import { useStaticQuery, graphql } from 'gatsby'

// :::: GET Article from Article title ::::
export const getStrapiEventFromTitle = title => {
  const { event } = useStaticQuery(allStrapiBlogCategoryQuery)

  const item = event.nodes.filter(node => node.title === title)
  return item[0]
}

const allStrapiBlogCategoryQuery = graphql`
  query {
    event: allStrapiEvent {
      nodes {
        title
        description {
          data {
            description
          }
        }
        categories {
          name
          color
          slug
          type
        }
        excerpt {
          data {
            excerpt
          }
        }
      }
    }
  }
`
