import React from 'react'
import { Box } from 'theme-ui'
import memphis from '../../assets/memphis.png'
import heroBg from '../../assets/heroBg.png'

const styles = {
  pattern: {
    size: `full`,
    backgroundImage: `url(${memphis})`,
    backgroundSize: `11rem`,
    position: `absolute`,
    opacity: 0.05,
    top: 0,
    left: 0
  },
  customPattern: {
    size: `full`,
    backgroundImage: `url(${heroBg})`,
    backgroundSize: `cover`,
    backgroundPosition: `bottom`,
    position: `absolute`,
    opacity: 0.5,
    top: 0,
    left: 0
  }
}

const MemphisPattern = ({ sx = {}, ...props }) => {
  return (
    <Box
      sx={
        props.customHeroBgFlag
          ? { ...styles.customPattern, ...sx }
          : { ...styles.pattern, ...sx }
      }
      {...props}
    ></Box>
  )
}

export default MemphisPattern
