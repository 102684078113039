import { useStaticQuery, graphql } from 'gatsby'

export const getPostSeoData = title => {
  const {
    allStrapiBlog,
    allStrapiPublication,
    allStrapiTheAmericasQuarterlyReview,
    allStrapiEvent
  } = useStaticQuery(seoDataQuery)

  const data = [
    ...allStrapiBlog.nodes,
    ...allStrapiEvent.nodes,
    ...allStrapiTheAmericasQuarterlyReview.nodes,
    ...allStrapiPublication.nodes
  ]

  const list = data.filter(data => data.title === title)
  return list[0]
}

const seoDataQuery = graphql`
  query {
    allStrapiBlog {
      nodes {
        seo {
          keywords
          metaDescription
          metaTitle
          metaImage {
            url
          }
        }
      }
    }

    allStrapiPublication {
      nodes {
        seo {
          metaDescription
          keywords
          metaImage {
            url
          }
          metaTitle
        }
        title
      }
    }

    allStrapiTheAmericasQuarterlyReview {
      nodes {
        seo {
          keywords
          metaDescription
          metaTitle
          metaImage {
            url
          }
        }
      }
    }

    allStrapiEvent {
      nodes {
        seo {
          keywords
          metaDescription
          metaTitle
          metaImage {
            url
          }
        }
      }
    }
  }
`
