import React from 'react'
import { Layout, Stack, Main, PreFooter } from '@layout'
import PageTitle from '@components/PageTitle'
import CardList from '@components/CardList'
import Divider from '@components/Divider'
import Seo from '@widgets/Seo'
import { attachPostDetails, attachAuthorDetails } from '@utils-blog'

const PageCollection = ({
  data: { blogs, publications, amricasReview, collectionInfo },
  ...props
}) => {
  blogs.nodes = addKey(blogs.nodes, 'News')

  publications.nodes = addKey(publications.nodes, 'Publication')
  amricasReview.nodes = addKey(
    amricasReview.nodes,
    'The Americas Quarterly Review'
  )

  attachAuthorDetails(collectionInfo)

  let allCatregoriesdata = [
    ...blogs.nodes,
    ...publications.nodes,
    ...amricasReview.nodes
  ]
  const item = groupBy([...allCatregoriesdata], c => c.belongTo)

  function groupBy(xs, f) {
    return xs.reduce(
      (r, v, i, a, k = f(v)) => ((r[k] || (r[k] = [])).push(v), r),
      {}
    )
  }

  function addKey(list, key) {
    list.forEach(item => {
      item.belongTo = key
    })
    return list
  }
  const object = { nodes: allCatregoriesdata }
  attachPostDetails(object) // Attach missing post details

  const SEO = collectionInfo?.seo
  let seoData = {
    description: SEO?.metaDescription,
    title: SEO?.metaTitle,
    keywords: SEO?.keywords?.split('\n'),
    metaImage: SEO?.metaImage
  }

  return (
    <Layout {...props}>
      <Seo
        title={seoData?.title}
        description={seoData?.description}
        keywords={seoData?.keywords}
        thumbnail={seoData?.metaImage}
        socialNetwork={seoData?.social}
      />
      <Divider />
      <Stack effectProps={{ effect: 'fadeInDown' }}>
        <PageTitle
          header={collectionInfo.name}
          subheader={collectionInfo.title}
          totalCount={allCatregoriesdata.length}
        />
      </Stack>
      <Divider />
      {Object.keys(item).map(key => {
        return (
          <Stack title={key}>
            <Main>
              {item[key] && (
                <>
                  <CardList
                    nodes={item[key]}
                    variant={['horizontal-md', 'vertical']}
                    columns={[1, 2, 3, 3]}
                    callFrom={key}
                    omitCategory
                  />
                  <Divider />
                </>
              )}
            </Main>
            <Divider />
          </Stack>
        )
      })}
      <Divider />
      {/* <PreFooter>
        <Pagination
          {...posts.pageInfo}
          {...collectionInfo}
          {...{
            slug: `${
              props.pageContext.collectionType
                ? `/${props.pageContext.collectionType}`
                : ''
            }/${collectionInfo.slug}/`
          }}
        />
      </PreFooter> */}
    </Layout>
  )
}

export default PageCollection
