import { useStaticQuery, graphql } from 'gatsby'

export const getHeroData = () => {
  const {
    Events,
    About,
    Staff,
    Partners,
    Blogs,
    Publication,
    Americas,
    Grant,
    Scholarship
  } = useStaticQuery(heroDataQuery)

  return {
    Events: Events?.nodes[0]?.hero,
    About: About?.nodes[0]?.hero,
    Staff: Staff?.nodes[0]?.hero,
    Partners: Partners?.nodes[0]?.hero,
    Blogs: Blogs?.nodes[0]?.hero,
    Publication: Publication?.nodes[0]?.hero,
    Americas: Americas?.nodes[0]?.hero,
    Grant: Grant?.nodes[0]?.hero,
    Scholarship: Scholarship?.nodes[0]?.hero
  }
}

const heroDataQuery = graphql`
  query {
    Events: allStrapiEventsPage {
      nodes {
        hero {
          title
          description
          featuredImage {
            url
          }
          buttons {
            url
            name
          }
        }
      }
    }
    About: allStrapiAboutPage {
      nodes {
        hero {
          buttons {
            url
            name
          }
          description
          title
          featuredImage {
            url
          }
        }
      }
    }

    Staff: allStrapiStaffsPage {
      nodes {
        hero {
          buttons {
            url
            name
          }
          description
          title
          featuredImage {
            url
          }
        }
      }
    }

    Partners: allStrapiPartner {
      nodes {
        hero {
          buttons {
            url
            name
          }
          description
          title
          featuredImage {
            url
          }
        }
      }
    }

    Blogs: allStrapiBlogsPage {
      nodes {
        hero {
          buttons {
            url
            name
          }
          description
          title
          featuredImage {
            url
          }
        }
      }
    }

    Publication: allStrapiPublicationsPage {
      nodes {
        hero {
          buttons {
            url
            name
          }
          description
          title
          featuredImage {
            url
          }
        }
      }
    }

    Americas: allStrapiTheAmericasQuarterlyReviewPage {
      nodes {
        hero {
          buttons {
            url
            name
          }
          description
          title
          featuredImage {
            url
          }
        }
      }
    }

    Grant: allStrapiGrantPage {
      nodes {
        hero {
          buttons {
            url
            name
          }
          description
          title
          featuredImage {
            url
          }
        }
      }
    }

    Scholarship: allStrapiScholarshipsPage {
      nodes {
        hero {
          buttons {
            url
            name
          }
          description
          title
          featuredImage {
            url
          }
        }
      }
    }
  }
`
