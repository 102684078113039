import React from 'react'
import { Box } from 'theme-ui'
import { getHeroData } from '../../helpers/nr-getHeroData.helper.js'

const styles = {
  image: image => ({
    display: [`none`, null, `block`],
    height: `120%`,
    width: `1/2`,
    minWidth: 380,
    maxHeight: 514,
    borderRadius: `default`,
    backgroundImage: `url(${image})`,
    backgroundRepeat: `no-repeat`,
    backgroundSize: `cover`,
    // filter: `grayscale(1)`,
    overflowX: `hidden`,
    position: `absolute`,
    bottom: 0,
    right: 0,
    zIndex: 2
  }),
  customImage: image => ({
    display: [`none`, null, `block`],
    height: `275`,
    width: `1/3`,
    minWidth: 350,
    maxHeight: 275,
    minHeight: 275,
    borderRadius: `default`,
    backgroundImage: `url(${image})`,
    backgroundRepeat: `no-repeat`,
    backgroundSize: `cover`,
    backgroundColor: `#d1d4d9`,
    // filter: `grayscale(1)`,
    overflowX: `hidden`,
    position: `absolute`,
    transform: 'translateY(-50%)',
    borderRadius: `0 40px 40px 40px`,
    top: `50%`,
    right: 0,
    zIndex: 2
  }),
  border: {
    '&::before, &::after': {
      content: `""`,
      position: `absolute`
    },
    '::before': {
      bg: `#E4E7EB`,
      top: `calc(50% - 147px)`,
      right: `194px`,
      width: `175px`,
      height: `133px`
    },
    '::after': {
      bg: `#DBE0E7`,
      width: `175px`,
      height: `133px`,
      right: `-8px`,
      top: `calc(50% - -11px)`,
      borderRadius: `0px 3px 40px 0px`
    }
  }
}

export default ({ callFrom = 'Home', ...props }) => {
  const {
    Events,
    About,
    Staff,
    Partners,
    Blogs,
    Publication,
    Americas,
    Grant,
    Scholarship
  } = getHeroData()
  let publicURL

  if (props.heroType === 'events') {
    publicURL = Events?.featuredImage.url
    if (!publicURL) return ''
  }
  if (props.heroType === 'about') {
    publicURL = About?.featuredImage.url
    if (!publicURL) return ''
  }
  if (props.heroType === 'staff') {
    publicURL = Staff?.featuredImage.url
    if (!publicURL) return ''
  }
  if (props.heroType === 'partners') {
    publicURL = Partners?.featuredImage.url
    if (!publicURL) return ''
  }
  if (props.heroType === 'blogs') {
    publicURL = Blogs?.featuredImage.url
    if (!publicURL) return ''
  }
  if (props.heroType === 'publication') {
    publicURL = Publication?.featuredImage.url
    if (!publicURL) return ''
  }
  if (props.heroType === 'americas') {
    publicURL = Americas?.featuredImage.url
    if (!publicURL) return ''
  }
  if (props.heroType === 'grant') {
    publicURL = Grant?.featuredImage.url
    if (!publicURL) return ''
  }
  if (props.heroType === 'scholarships') {
    publicURL = Scholarship?.featuredImage.url
    if (!publicURL) return ''
  }

  return (
    <Box sx={styles.border}>
      <Box
        sx={
          props.customHeroPhotoFlag
            ? styles.customImage(publicURL || {})
            : styles.image(publicURL || {})
        }
      ></Box>
    </Box>
  )
}
