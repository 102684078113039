import React from 'react'
import { Layout, Stack, Main, Hero } from '@layout'
import Divider from '@components/Divider'
import Seo from '@widgets/Seo'
import { Box } from 'theme-ui'
import { getStaffData } from '../helpers/nr-staff.helper'
import StaffList from '../helpers/staffList.js'
import Section from '@components/Section/Section'
import HeroComponent from '../../../gatsby-theme-flexiblog-personal/src/components/Hero/Hero'
import MemphisPattern from '@components/MemphisPattern/MemphisPattern'

/**
 * Shadow me to add your own content
 */

const styles = {
  boxs: {
    display: `grid`,
    gridTemplateColumns: 'auto auto',
    gap: '20px',
    '@media screen and (max-width: 600px)': {
      gridTemplateColumns: 'auto'
    },
    '>div >div': {
      height: `100%`
    }
  }
}

export default props => {
  const { data, seo, hero } = getStaffData()

  let groupByType = data.reduce(
    (group, arr) => {
      const { type } = arr

      group[type] = group[type] ?? []

      group[type].push(arr)

      return group
    },

    {}
  )
  let seoData = {
    description: seo?.metaDescription,
    title: seo?.metaTitle,
    keywords: seo?.keywords?.split('\n'),
    metaImage: seo?.metaImage
  }

  return (
    <Layout {...props}>
      <Seo
        title={seoData?.title}
        description={seoData?.description}
        keywords={seoData?.keywords}
        thumbnail={seoData?.metaImage}
        socialNetwork={seoData?.social}
      />
      <Hero full>
        <Hero style={{ zIndex: 1 }}>
          <HeroComponent customHeroFlag={true} heroType={'staff'} {...props} />
        </Hero>
        <MemphisPattern customHeroBgFlag={true} />
      </Hero>
      <Divider />
      <Stack>
        <Main>
          {Object.keys(groupByType).map(key => {
            return (
              <>
                <Divider />
                <Section title={key}>
                  <Box sx={styles.boxs}>
                    <StaffList list={groupByType[key]} />
                    <Divider />
                  </Box>
                </Section>
              </>
            )
          })}
          <Divider />
        </Main>
      </Stack>
    </Layout>
  )
}
