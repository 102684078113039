import React, { useEffect } from 'react'
import { Layout, Stack, Hero, Main, Sidebar, PreFooter } from '@layout'
import Seo from '@widgets/Seo'
import { PostBody, PostImage } from '@widgets/Post'
import { Card as CardComponent, Heading } from 'theme-ui'
import Card from '@components/Card'
import Divider from '@components/Divider'
import { useScholarshipPage } from '@helpers-blog'
import theme from '@elegantstack/flow-ui-theme/src/theme'
import HeroComponent from '../../../../themes/gatsby-theme-flexiblog-personal/src/components/Hero/Hero'
import MemphisPattern from '@components/MemphisPattern/MemphisPattern'

const ScholarshipsPage = props => {
  const { scholarshipDetail, SEO, hero } = useScholarshipPage()

  let seoData = {
    description: SEO?.metaDescription,
    title: SEO?.metaTitle,
    keywords: SEO?.keywords?.split('\n'),
    metaImage: SEO?.metaImage
  }

  useEffect(() => {
    const { linkColor, linkHoverColor } = theme.colors
    document
      .querySelector(':root')
      .style.setProperty('--anchor-tag-color', linkColor)
    document
      .querySelector(':root')
      .style.setProperty('--anchor-tag-hover-color', linkHoverColor)
  }, [])

  return (
    <Layout {...props}>
      <Seo
        title={seoData?.title}
        description={seoData?.description}
        keywords={seoData?.keywords}
        thumbnail={seoData?.metaImage}
        socialNetwork={seoData?.social}
      />
      <Hero full>
        <Hero style={{ zIndex: 1 }}>
          <HeroComponent
            customHeroFlag={true}
            heroType={'scholarships'}
            {...props}
          />
        </Hero>
        <MemphisPattern customHeroBgFlag={true} />
      </Hero>
      <Divider />
      <Stack effectProps={{ fraction: 0 }}>
        <Main>
          <Divider />
          {scholarshipDetail.Scholarships.map(item => {
            return (
              <>
                <CardComponent variant='paper'>
                  {/* <PostImage
                    featuredImage={
                      item?.thumbnail?.ImageSharp_vertical?.gatsbyImageData
                    }
                    callFrom='Scholarship'
                    inCard
                  /> */}
                  <Heading variant='h3' as='h3'>
                    {item.title}
                  </Heading>
                  <PostBody
                    bodyContent={item.description.data.description}
                  ></PostBody>
                </CardComponent>
                <Divider />
              </>
            )
          })}
        </Main>
      </Stack>
    </Layout>
  )
}

export default ScholarshipsPage
