import { graphql } from 'gatsby'
import Collection from '../containers/Blog'

export default Collection

export const pageQuery = graphql`
  query myQuery($id: String!, $previousId: String, $nextId: String) {
    blogDetail: blog(id: { eq: $id }) {
      ...BlogInformation
    }
    publicationDetail: publication(id: { eq: $id }) {
      ...PublicationInformation
    }
    americasReview: theAmericasQuarterlyReview(id: { eq: $id }) {
      ...AmericasReviewInformation
    }
    eventDetail: event(id: { eq: $id }) {
      ...EventInformation
    }

    previousBlog: blog(id: { eq: $previousId }) {
      id
      slug
      title
    }

    nextBlog: blog(id: { eq: $nextId }) {
      id
      slug
      title
    }
    previousEvent: event(id: { eq: $previousId }) {
      id
      slug
      title
    }

    nextEvent: event(id: { eq: $nextId }) {
      id
      slug
      title
    }
  }
`
