import React from 'react'
import { Card, Text, IconButton } from 'theme-ui'
import { FaPhone, FaEnvelope, FaLocationArrow } from 'react-icons/fa'
import Section from '@components/Section'
import useSiteMetadata from '@helpers-blog/useSiteMetadata'
import attachSocialIcons from '@helpers/attachSocialIcons'
import Navigation from '@components/Navigation'

const ContactInfo = () => {
  const { phone, address, email } = useSiteMetadata()
  const { social } = useSiteMetadata()

  return (
    <Section aside title='Partner with Us'>
      <Card variant='paper'>
        <Text variant='p'>
          Does your library or museum house resources related to the Franciscan
          tradition? Did you know that we can add all different types of
          resources to our digital collections? Contact us to find out how we
          can share your content.
        </Text>
        {/* {email && (
          <Text>
            <IconButton variant='simple' role='presentation'>
              <FaEnvelope />
            </IconButton>
            {email}
          </Text>
        )} */}
        <Navigation
          sx={{ marginLeft: '5px' }}
          items={attachSocialIcons(social)}
          variant={[`horizontal`, `vertical`]}
        />
      </Card>
    </Section>
  )
}

export default ContactInfo
