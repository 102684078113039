import { useStaticQuery, graphql } from 'gatsby'

export const getCallToActionData = () => {
  const { allStrapiHomePage } = useStaticQuery(CallToActionQuery)
  return allStrapiHomePage?.nodes[0].callToAction
}

const CallToActionQuery = graphql`
  query {
    allStrapiHomePage {
      nodes {
        callToAction {
          featuredImage {
            localFileImageSharp {
              gatsbyImageData
            }
          }
          button2 {
            url
            name
          }
          button1 {
            url
            name
          }
          description
          title
        }
      }
    }
  }
`
