import React from 'react'
import { Layout, Stack, Main, Hero, Sidebar, PreFooter } from '@layout'
import CardList from '@components/CardList'
import Sticky from '@components/Sticky'
import Divider from '@components/Divider'
import Pagination from '@components/Pagination'
import Card from '@components/Card'
import Seo from '@widgets/Seo'
import Categories from '@widgets/Categories'
import { useBlogTags, useBlogCategories } from '@helpers-blog'
import { attactExcerptToPosts } from '@utils-blog/nr-useArticlesUtils.jsx'
import { getHeroData } from '@helpers-blog/nr-blogsHero.helper.js'
import HeroComponent from '../../../../themes/gatsby-theme-flexiblog-personal/src/components/Hero/Hero'
import MemphisPattern from '@components/MemphisPattern/MemphisPattern'

const TheAmericasQuarterlyReview = props => {
  let categories = useBlogCategories()
  categories = categories.filter(
    item => item.type === 'The Americas Quarterly Review'
  )

  const tags = useBlogTags()
  const paginatedPosts = props.data.paginated

  const { americas } = getHeroData()
  let seoData = {
    description: americas?.seo?.metaDescription,
    title: americas?.seo?.metaTitle,
    keywords: americas?.seo?.keywords?.split('\n'),
    metaImage: americas?.seo?.metaImage
  }
  attactExcerptToPosts(paginatedPosts)

  const { pageContext: { services = {}, basePath } = {} } = props
  return (
    <Layout {...props}>
      <Seo
        title={seoData?.title}
        description={seoData?.description}
        keywords={seoData?.keywords}
        thumbnail={seoData?.metaImage}
        socialNetwork={seoData?.social}
      />
      <Hero full>
        <Hero style={{ zIndex: 1 }}>
          <HeroComponent
            customHeroFlag={true}
            heroType={'americas'}
            {...props}
          />
        </Hero>
        <MemphisPattern customHeroBgFlag={true} />
      </Hero>
      <Divider />
      <Stack>
        <Main>
          <CardList
            variant={[
              'horizontal-md',
              'horizontal',
              'paper',
              'horizontal',
              'horizontal-lg'
            ]}
            addStyle={true}
            callFrom='americasReview'
            nodes={paginatedPosts.nodes}
            columns={[1, 1, 2]}
            aside
          />
        </Main>
        {/* <Sidebar>
          <Sticky>
            <Categories categories={categories} />
            <Divider /> */}
        {/* <Tags tags={tags} />
          <Divider />
          <Social />
            {services.mailchimp && (
              <>
                <Divider />
                <NewsletterCompact />
              </>
            )} */}
        {/* </Sticky>
        </Sidebar> */}
      </Stack>
      <Divider />
      <PreFooter>
        <Pagination
          {...paginatedPosts.pageInfo}
          prefix={'the-americas-quarterly-review'}
        />
      </PreFooter>
    </Layout>
  )
}
export default TheAmericasQuarterlyReview
