import React, { useEffect } from 'react'
import { Layout, Stack, Hero, Main } from '@layout'
import Seo from '@widgets/Seo'
import { PostBody } from '@widgets/Post'
import { Heading } from 'theme-ui'
import Card from '@components/Card'
import Divider from '@components/Divider'
import { useGrantPage } from '@helpers-blog'
import theme from '@elegantstack/flow-ui-theme/src/theme'
import HeroComponent from '../../../../themes/gatsby-theme-flexiblog-personal/src/components/Hero/Hero'
import MemphisPattern from '@components/MemphisPattern/MemphisPattern'

const GrantPage = props => {
  const { data, seo, hero } = useGrantPage()

  let seoData = {
    description: seo?.metaDescription,
    title: seo?.metaTitle,
    keywords: seo?.keywords?.split('\n'),
    metaImage: seo?.metaImage
  }

  useEffect(() => {
    const { linkColor, linkHoverColor } = theme.colors
    document
      .querySelector(':root')
      .style.setProperty('--anchor-tag-color', linkColor)
    document
      .querySelector(':root')
      .style.setProperty('--anchor-tag-hover-color', linkHoverColor)
  }, [])

  return (
    <Layout {...props}>
      <Seo
        title={seoData?.title}
        description={seoData?.description}
        keywords={seoData?.keywords}
        thumbnail={seoData?.metaImage}
        socialNetwork={seoData?.social}
      />
      <Hero full>
        <Hero style={{ zIndex: 1 }}>
          <HeroComponent customHeroFlag={true} heroType={'grant'} {...props} />
        </Hero>
        <MemphisPattern customHeroBgFlag={true} />
      </Hero>
      <Divider />
      <Stack effectProps={{ fraction: 0 }}>
        <Main>
          <Heading variant='h3' as='h3'>
            {data.title}
          </Heading>
          <PostBody bodyContent={data.description.data.description}></PostBody>
        </Main>
      </Stack>
    </Layout>
  )
}
export default GrantPage
