import React from 'react'
import { Layout, Stack, Hero, Main, Sidebar, PreFooter } from '@layout'
import CardList from '@components/CardList'
import Sticky from '@components/Sticky'
import Divider from '@components/Divider'
import Pagination from '@components/Pagination'
import Seo from '@widgets/Seo'
import Categories from '@widgets/Categories'
import { useBlogTags, useBlogCategories } from '@helpers-blog'
import { attactExcerptToPosts } from '@utils-blog/nr-useArticlesUtils.jsx'
import { getHeroData } from '@helpers-blog/nr-blogsHero.helper.js'
import HeroComponent from '../../../../themes/gatsby-theme-flexiblog-personal/src/components/Hero/Hero'
import MemphisPattern from '@components/MemphisPattern/MemphisPattern'

// import { readingTime } from 'reading-time-estimator'

const Publications = props => {
  let categories = useBlogCategories()
  categories = categories.filter(item => item.type === 'Publication')

  const tags = useBlogTags()
  const paginatedPosts = props.data.paginated

  attactExcerptToPosts(paginatedPosts)

  const { publications } = getHeroData()
  let seoData = {
    description: publications?.seo?.metaDescription,
    title: publications?.seo?.metaTitle,
    keywords: publications?.seo?.keywords?.split('\n'),
    metaImage: publications?.seo?.metaImage
  }

  //   paginatedPosts.nodes.forEach(ele => {
  //     ele.bodyContent = getReadTimeFromBlog(ele.title)
  //     if (ele.bodyContent && ele.bodyContent.body) {
  //       const text = ele.bodyContent.body.data.body.replace(/(<([^>]+)>)/gi, '')
  //       ele.readingTime = readingTime(text, 220)
  //     }
  //   })

  // attachCategoriesToEvent(paginatedPosts)
  const { pageContext: { services = {}, basePath } = {} } = props
  return (
    <Layout {...props}>
      <Seo
        title={seoData?.title}
        description={seoData?.description}
        keywords={seoData?.keywords}
        thumbnail={seoData?.metaImage}
        socialNetwork={seoData?.social}
      />
      <Hero full>
        <Hero style={{ zIndex: 1 }}>
          <HeroComponent
            customHeroFlag={true}
            heroType={'publication'}
            {...props}
          />
        </Hero>
        <MemphisPattern customHeroBgFlag={true} />
      </Hero>
      <Divider />
      <Hero>
        <CardList
          nodes={paginatedPosts.nodes}
          addStyle={true}
          callFrom='Publications'
          columns={[1, 1, 2]}
          variant={[
            'horizontal-md',
            'horizontal',
            'paper',
            'horizontal',
            'horizontal-lg'
          ]}
          // title='Trending Now'
          aside
        />
      </Hero>
      <Divider />
      <PreFooter>
        <Pagination {...paginatedPosts.pageInfo} prefix={'publications'} />
      </PreFooter>
    </Layout>
  )
}
export default Publications
