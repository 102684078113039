import React, { useEffect } from 'react'
import { Layout, Stack, Hero, Main } from '@layout'
import Seo from '@widgets/Seo'
import { PostBody } from '@widgets/Post'
import { Heading } from 'theme-ui'
import Card from '@components/Card'
import Divider from '@components/Divider'
import { getAboutData } from '../helpers/nr-about-page.helper'
import theme from '@elegantstack/flow-ui-theme/src/theme'
import HeroComponent from '../../../gatsby-theme-flexiblog-personal/src/components/Hero/Hero'
import MemphisPattern from '@components/MemphisPattern/MemphisPattern'

const AboutPage = props => {
  const { data, seo, hero } = getAboutData()

  let SEOData = {
    description: seo?.metaDescription,
    title: seo?.metaTitle,
    keywords: seo?.keywords?.split('\n'),
    metaImage: seo?.metaImage
  }

  useEffect(() => {
    const { linkColor, linkHoverColor } = theme.colors
    document
      .querySelector(':root')
      .style.setProperty('--anchor-tag-color', linkColor)
    document
      .querySelector(':root')
      .style.setProperty('--anchor-tag-hover-color', linkHoverColor)
  }, [])

  return (
    <Layout {...props}>
      <Seo
        title={SEOData?.title}
        description={SEOData?.description}
        keywords={SEOData?.keywords}
        thumbnail={SEOData?.metaImage}
        socialNetwork={SEOData?.social}
      />
      <Hero full>
        <Hero style={{ zIndex: 1 }}>
          <HeroComponent customHeroFlag={true} heroType={'about'} {...props} />
        </Hero>
        <MemphisPattern customHeroBgFlag={true} />
      </Hero>
      <Divider />
      <Stack effectProps={{ fraction: 0 }}>
        <Main>
          <Heading variant='h3' as='h3'></Heading>
          <PostBody bodyContent={data.description.data.description}></PostBody>
        </Main>
      </Stack>
    </Layout>
  )
}
export default AboutPage
