import React from 'react'
import { Box } from 'theme-ui'
import { Layout, Stack, Hero, Main, Sidebar, PreFooter } from '@layout'
import CardList from '@components/CardList'
import Divider from '@components/Divider'
import Seo from '@widgets/Seo'
import Categories from '@widgets/Categories'
import { useBlogTags, useBlogCategories } from '@helpers-blog'
import { attachExcerptToPosts, attachCategoriesToPosts } from '@utils-blog'
import Testimonial from '@widgets/Testimonial'

const Posts = ({
  data: {
    paginatedPosts = {},
    recentPublications = {},
    featuredBlog={},
    recentBlog = {},
    singlePublication = {},
    recentEvent = {},
    homePageEvent={},
    homePagePublications={},
    homePagePosts={},
    recentAmericasQuaterlyReview={},
    seo
  },
  ...props
}) => {
  const { pageContext: { services = {}, basePath } = {} } = props
  const tags = useBlogTags()
  const sliderRef = React.useRef()
  let categories = useBlogCategories()
  categories = categories.filter(data => data.type === 'News')
  attachExcerptToPosts(paginatedPosts)
  attachExcerptToPosts(featuredBlog)
  attachExcerptToPosts(homePageEvent)
  attachExcerptToPosts(homePagePosts)
  attachExcerptToPosts(homePagePublications)
  attachExcerptToPosts(recentPublications)
  attachCategoriesToPosts(paginatedPosts)
  // attachCategoriesToPosts(featuredBlog)
  attachExcerptToPosts(singlePublication)
  attachExcerptToPosts(recentBlog)
  attachExcerptToPosts(recentEvent)
  recentEvent.nodes.forEach(item => {
    item.callFromEvent = 'Event'
  })
  homePageEvent.nodes.forEach(item => {
    item.callFromEvent = 'Event'
  })
  const list = [
    ...recentEvent.nodes,
    ...singlePublication.nodes,
    ...recentBlog.nodes,
    ...recentAmericasQuaterlyReview.nodes
  ]
  let recentPosts = list.sort((a, b) => {
    return new Date(b.updatedAt) - new Date(a.updatedAt)
  })
 
  if(featuredBlog.nodes.length>0){
    featuredBlog.nodes.forEach(item=>{
      item.slug='/about'
      recentPosts.unshift(item)
    })
  }
 
  recentPosts=recentPosts.filter((v,i,a)=>a.findIndex(v2=>(v2.title===v.title))===i)
  const SEO = seo?.nodes[0].seo
  let seoData = {
    description: SEO?.metaDescription,
    title: SEO?.metaTitle,
    keywords: SEO?.keywords?.split('\n'),
    metaImage: SEO?.metaImage
  }

  return (
    <Layout {...props}>
      <Seo
        title={seoData?.title}
        description={seoData?.description}
        keywords={seoData?.keywords}
        thumbnail={seoData?.metaImage}
      />
      <Hero
        pt={4}
        pb={5}
        sx={{
          background: t =>
            `linear-gradient(
              0deg,
              ${t.colors.aafhBg200},
              ${t.colors.aafhBg100}
            )`
        }}
      >
        <Divider space={3} />
        <Box sx={{ position: `relative`, zIndex: 3 }}>
          <CardList
            nodes={recentPosts}
            variant={['horizontal-hero']}
            limit={3}
            omitFooter
            slider
            autoPlay
            fade
            callFrom='mainHero'
            arrows={false}
            controlPosition='bottom'
            ref={sliderRef}
            loading='eager'
          />
          <Box sx={{ display: [`none`, null, `block`] }}>
            <Divider />
            <CardList
              nodes={recentPosts}
              variant={['horizontal-md', 'horizontal-aside']}
              limit={3}
              callFrom='mainSmallHero'
              columns={[1, 0, 3]}
              omitCategory
              asNavFor={sliderRef}
              loading='eager'
            />
          </Box>
        </Box>
      </Hero>
      <Divider />
      <Stack>
        <Main>
          <Testimonial />
        </Main>
      </Stack>
      <Divider space={50} />
      <Stack title='Recent Publications' titleLink='publications'>
        <Main>
          <CardList
            variant={[
              'horizontal-md',
              'horizontal',
              'paper',
              'horizontal',
              'horizontal-lg'
            ]}
            aside
            addStyle={true}
            callFrom='Publications'
            nodes={homePagePublications.nodes}
            columns={[1, 2, 2]}
            limit={4}
          />
        </Main>
      </Stack>
      <Divider />
      <Stack title='Recent News' titleLink='news'>
        <Main>
          <CardList
            variant={['horizontal-md', 'horizontal']}
            nodes={homePagePosts.nodes}
            columns={[1]}
            callFrom='Blog'
            limit={3}
            omitCategory
          />
        </Main>
        <Sidebar>
          <Categories categories={categories} />
          <Divider />
          {/* <Tags tags={tags} />
          <Divider />
          <Social />
          <Sticky>
            {services.mailchimp && (
              <>
                <Divider />
                <NewsletterCompact />
              </>
            )}
          </Sticky> */}
        </Sidebar>
      </Stack>
      <Divider />
      <Stack title='Latest Events' titleLink='events'>
        <Main>
          <CardList
            variant={['horizontal-md', 'vertical']}
            callFrom='Events'
            nodes={homePageEvent.nodes}
            columns={[1, 3]}
            limit={3}
          />
        </Main>
      </Stack>
    </Layout>
  )
}

export default Posts
