import { useStaticQuery, graphql } from 'gatsby'

export const getPartnersData = () => {
  const { allStrapiPartner } = useStaticQuery(partnersDataQuery)
  return {
    data: allStrapiPartner?.nodes[0]?.partner,
    seo: allStrapiPartner?.nodes[0]?.seo,
    hero: allStrapiPartner?.nodes[0]?.hero
  }
}

const partnersDataQuery = graphql`
  query {
    allStrapiPartner {
      nodes {
        partner {
          link
          title
          featuredImage {
            ImageSharp_vertical: localFileImageSharp {
              gatsbyImageData
            }
          }
          excerpt {
            data {
              excerpt
            }
          }
        }
        hero {
          title
          description
          featuredImage {
            ImageSharp_vertical: localFileImageSharp {
              gatsbyImageData(aspectRatio: 3.5)
            }
          }
          buttons {
            url
            name
          }
        }

        seo {
          metaDescription
          keywords
          id
          metaTitle
          metaImage {
            url
          }
        }
      }
    }
  }
`
