import { graphql } from 'gatsby'
import Collection from '../containers/Collection.Author'

export default Collection

export const pageQuery = graphql`
  query allArticleByAuthorQuery($slug: String!) {
    collectionInfo: strapiAuthor(slug: { eq: $slug }) {
      id
      name
      slug
      title
      authorDescription {
        data {
          authorDescription
        }
      }
      seo {
        keywords
        metaDescription
        metaTitle
        metaImage {
          url
        }
      }
      social {
        name
        url
      }
    }

    blogs: allStrapiBlog(
      filter: { authors: { elemMatch: { slug: { eq: $slug } } } }
      sort: { fields: [date], order: DESC }
    ) {
      nodes {
        date
        createdAt
        slug
        title
        id
        featuredImage {
          ImageSharp_vertical: localFileImageSharp {
            gatsbyImageData(
              width: 360
              height: 470
              transformOptions: { cropFocus: CENTER }
              outputPixelDensities: [0.5, 1]
            )
          }
        }
        description {
          data {
            id
            description
          }
        }
      }
    }

    publications: allStrapiPublication(
      filter: { authors: { elemMatch: { slug: { eq: $slug } } } }
      sort: { fields: [date], order: DESC }
    ) {
      nodes {
        date
        createdAt
        slug
        purchaseLink
        title
        id
        featuredImage {
          ImageSharp_vertical: localFileImageSharp {
            gatsbyImageData(
              width: 360
              height: 470
              transformOptions: { cropFocus: CENTER }
              outputPixelDensities: [0.5, 1]
            )
          }
        }
        description {
          data {
            id
            description
          }
        }
      }
    }

    amricasReview: allStrapiTheAmericasQuarterlyReview(
      filter: { authors: { elemMatch: { slug: { eq: $slug } } } }
      sort: { fields: [date], order: DESC }
    ) {
      nodes {
        date
        createdAt
        slug
        title
        subscribeLink
        id
        featuredImage {
          ImageSharp_vertical: localFileImageSharp {
            gatsbyImageData(
              width: 360
              height: 470
              transformOptions: { cropFocus: CENTER }
              outputPixelDensities: [0.5, 1]
            )
          }
        }
        description {
          data {
            id
            description
          }
        }
      }
    }
  }
`
