import React, { useState, useEffect, useRef } from 'react'
import Seo from '@widgets/Seo'
import { BsCalendar3 } from 'react-icons/bs'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { Layout, Stack, Main, Hero } from '@layout'
import Divider from '@components/Divider'
import { Heading, Box } from 'theme-ui'
import CardList from '@components/CardList/CardList'
import { attachCategoriesToEvent } from '@utils-blog'
import { attactExcerptToPosts } from '@utils-blog/nr-useArticlesUtils.jsx'
import { getHeroData } from '@helpers-blog/nr-blogsHero.helper.js'
import HeroComponent from '../../../../themes/gatsby-theme-flexiblog-personal/src/components/Hero/Hero'
import MemphisPattern from '@components/MemphisPattern/MemphisPattern'

const Input = ({
  onChange,
  placeholder,
  value,
  onClick,
  isSecure,
  id,
  onOpenCalendar
}) => {
  return (
    <div style={styles.inputWrapper}>
      <BsCalendar3 style={styles.cursor} onClick={onOpenCalendar} />
      <input
        onClick={onOpenCalendar}
        style={styles.customInput}
        onChange={onChange}
        value={value}
        id={id}
        placeholder={placeholder}
      />
    </div>
  )
}

const styles = {
  customInput: {
    border: 'none',
    padding: '9px',
    width: '100%',
    outline: 'none',
    background: 'transparent'
  },
  inputWrapper: {
    border: '1px solid #2f3747',
    padding: '6px',
    display: 'flex',
    alignItems: 'center',
    background: '#fff',
    borderRadius: '5px'
  },
  cursor: {
    cursor: 'pointer'
  },
  wrapper: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  datePickerBox: {
    width: '270px'
  }
}
const Events = props => {
  const events = props.data.paginated
  attachCategoriesToEvent(events)
  attactExcerptToPosts(events)

  const [startDate, setStartDate] = useState()
  const [openCalendar, setOpenCalendar] = useState(false)
  const [item, setItem] = useState({})
  const [endDate, setEndDate] = useState()

  events.nodes.forEach(ele => {
    ele.category = ele.categories[0].name
    delete ele.categories
  })

  const { event } = getHeroData()
  let seoData = {
    description: event?.seo?.metaDescription,
    title: event?.seo?.metaTitle,
    keywords: event?.seo?.keywords?.split('\n'),
    metaImage: event?.seo?.metaImage
  }

  let list = groupBy([...events.nodes], c => c.category)

  useEffect(() => {
    const last = events.nodes.length - 1

    setStartDate(new Date(events.nodes[last].date))
    setEndDate(new Date(events.nodes[0].date))
    filterDate(
      new Date(events.nodes[last].date),
      new Date(events.nodes[0].date)
    )
  }, [])

  const onChange = dates => {
    const [start, end] = dates
    setStartDate(start)
    setEndDate(end)
    if (end) {
      setOpenCalendar(false)
    }
    filterDate(start, end ? end : new Date())
  }

  const filterDate = (start, end) => {
    list = groupBy([...events.nodes], c => c.category)
    Object.keys(list).forEach(ele => {
      list[ele] = list[ele].filter(event => {
        return (
          new Date(event.date).getTime() >= start.getTime() &&
          new Date(event.date).getTime() <= end.getTime()
        )
      })
    })
    setItem({})
    setItem({ ...list })
  }

  function groupBy(xs, f) {
    return xs.reduce(
      (r, v, i, a, k = f(v)) => ((r[k] || (r[k] = [])).push(v), r),
      {}
    )
  }
  const onOpenCalendar = () => {
    setOpenCalendar(true)
  }
  const closeCalendar = () => {
    setOpenCalendar(false)
  }

  return (
    <Layout {...props}>
      <Seo
        title={seoData?.title}
        description={seoData?.description}
        keywords={seoData?.keywords}
        thumbnail={seoData?.metaImage}
        socialNetwork={seoData?.social}
      />
      <Hero full>
        <Hero style={{ zIndex: 1 }}>
          <HeroComponent customHeroFlag={true} heroType={'events'} {...props} />
        </Hero>
        <MemphisPattern customHeroBgFlag={true} />
      </Hero>
      <Divider />
      {Object.keys(item).map((key, index) => {
        return (
          <Stack>
            <Main>
              {index === 0 && (
                <div style={styles.wrapper}>
                  <Box sx={styles.datePickerBox}>
                    <DatePicker
                      selectsRange
                      dateFormat='MMMM dd yyyy'
                      placeholderText=' Choose Date...'
                      startDate={startDate}
                      onClickOutside={closeCalendar}
                      open={openCalendar}
                      shouldCloseOnSelect={true}
                      endDate={endDate}
                      customInput={<Input onOpenCalendar={onOpenCalendar} />}
                      onChange={onChange}
                    />
                    <Divider />
                  </Box>
                </div>
              )}
              <CardList
                title={key}
                nodes={item[key]}
                variant={['vertical']}
                columns={[2]}
                limit={5}
                skip={0}
                callFrom='events'
                omitFooter
              />
              <Divider />
            </Main>
          </Stack>
        )
      })}
    </Layout>
  )
}

export default Events
