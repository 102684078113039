import React from 'react'
import { Box, Text, Card, Heading } from 'theme-ui'
import { GatsbyImage as Img } from 'gatsby-plugin-image'
import { css } from 'theme-ui'

const styles = {
  wrapper: {
    textAlign: `center`,
    display: 'flex',
    alignItems: 'start'
  },
  avatarWrapper: {
    mb: 4,
    mr: 3,
    borderRadius: 20,
    overflow: 'hidden',
    '@media screen and (max-width: 600px)': {
      minWidth: `20%`
    }
  },
  section: {
    textAlign: 'start',
    maxWidth: '400px'
  },
  link: {
    a: {
      color: '#667eea',
      textDecoration: 'none',
      '&:hover': {
        color: '#5a67d8'
      }
    }
  }
}

const StaffList = ({ list }) => {
  const data = list.map((data, index) => {
    return (
      <>
        <Box>
          <Card variant={['paper']} key={index}>
            <Box sx={styles.wrapper}>
              <Box sx={styles.avatarWrapper}>
                <Img
                  css={css({
                    height: `full`,
                    verticalAlign: `middle`, //avoid baseline gap
                    img: {
                      bg: `omegaLighter`
                    }
                  })}
                  image={
                    data?.featuredImage?.localFileImageSharp?.gatsbyImageData
                  }
                  alt={data.title}
                />
              </Box>
              <Box sx={styles.section}>
                <Heading variant='h3'>{data.name}</Heading>
                <Text
                  sx={styles.link}
                  dangerouslySetInnerHTML={{
                    __html: data.description.data.description
                  }}
                />
              </Box>
            </Box>
          </Card>
        </Box>
      </>
    )
  })

  return data
}

export default StaffList
