import React from 'react'
import { Box, Heading, Button } from 'theme-ui'
import { Link } from 'gatsby'
import { getCallToActionData } from '../../../../themes/gatsby-theme-flexiblog-personal/src/helpers/nr-callToAction.helper'

/**
 * Shadow me to add your own content
 */

const styles = {
  wrapper: {
    ml: 3
  },
  heading: {
    color: `alpha`
  },
  running: {
    color: `omegaDark`,
    fontWeight: `body`
  }
}

export default () => {
  const callToAction = getCallToActionData()

  return (
    <Box sx={styles.wrapper}>
      <Heading variant='h1' sx={styles.heading}>
        {callToAction.title}
      </Heading>
      <Heading variant='h2' sx={styles.running}>
        {callToAction.description}
      </Heading>
      <Box variant='buttons.group' sx={styles.buttons}>
        {callToAction.button1.name
          ? <Button as={Link} to={callToAction.button1?.url}>
            {callToAction.button1?.name}
          </Button>
          : <></>}
        {callToAction.button2?.name ? <Button variant='white' as={Link} to={callToAction.button2?.url}>
          {callToAction.button2?.name}
        </Button>
          : <></>}
      </Box>
      {/* <Heading variant='h3'>Aubree Joyce</Heading> */}
    </Box>
  )
}
