import React from 'react'
import { Link } from 'gatsby'
import { Box, Heading, Button, Text } from 'theme-ui'
import Section from '@components/Section'
import { getHeroData } from '../../helpers/nr-getHeroData.helper.js'

/**
 * Shadow me to add your own content
 */

const styles = {
  heading: {
    mb: 4,
    span: {
      color: `omegaDark`
    }
  },
  running: {
    color: `omegaDark`,
    fontWeight: `body`,
    width: `5/6`
  },
  stringHtml: {
    p: {
      m: 0
    }
  },
  buttons: {
    a: {
      borderRadius: `12px`
    }
  }
}

export default ({ callFrom = 'Home', ...props }) => {
  const {
    Events,
    About,
    Staff,
    Partners,
    Blogs,
    Publication,
    Americas,
    Grant,
    Scholarship
  } = getHeroData()

  let heroData

  if (props.heroType === 'events') {
    heroData = Events
  }
  if (props.heroType === 'about') {
    heroData = About
  }
  if (props.heroType === 'staff') {
    heroData = Staff
  }
  if (props.heroType === 'partners') {
    heroData = Partners
  }
  if (props.heroType === 'blogs') {
    heroData = Blogs
  }
  if (props.heroType === 'publication') {
    heroData = Publication
  }
  if (props.heroType === 'americas') {
    heroData = Americas
  }
  if (props.heroType === 'grant') {
    heroData = Grant
  }
  if (props.heroType === 'scholarships') {
    heroData = Scholarship
  }

  return (
    <>
      <Section>
        <Heading variant='h1' sx={styles.heading}>
          {heroData.title}
        </Heading>
        <Heading variant='h3' sx={styles.running}>
          <Text sx={styles.stringHtml}>{heroData.description}</Text>
        </Heading>
      </Section>
      <Box variant='buttons.group' sx={styles.buttons}>
        {heroData?.buttons.map((btn, index) => {
          if (index % 2 !== 0) {
            return (
              (btn.name
                ? <Button as={Link} variant='white' to={btn.url}>
                  {btn.name}
                </Button>
                : <></>)
            )
          } else {
            return (
              (btn.name
                ? <Button as={Link} to={btn.url}>
                  {btn.name}
                </Button>
                : <></>)
            )
          }
        })}
      </Box>
    </>
  )
}
